<template>
  <div class="d-flex flex-column flex-grow-1 rounded mr-md-3">
    <BaseToolBar
      title="Satisfaction client"
      subtitle="Liste des satisfactions enregistrées"
    >      
      <BaseButton
      class="btn btn-primary ml-3"
      v-tooltip="'Ajouter une satisfaction client'"
      @click="createSatisfaction()"
      :loading="loading"
      icon="plus"
      text="Ajouter"
    ></BaseButton>
    </BaseToolBar>
    <div class="d-flex flex-column flex-md-row w-100">
      <div class="w-100" v-if="satisfactions">
        <div
          v-for="satisfaction in satisfactions"
          :key="satisfaction.id"
          class="w-100"
        >
          <SatisfactionItem
            :affaires="[]"
            :satisfaction="satisfaction"
            :lists="[]"
          ></SatisfactionItem>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapFields } from "vuex-map-fields";
import { mapMultiRowFields } from "vuex-map-fields";
import { mapActions, mapGetters } from "vuex";
import BaseToolBar from "@/components/bases/ToolBar.vue";
import SatisfactionItem from "@/components/affaires/SatisfactionItem.vue";
import BaseButton from "@/components/bases/Button.vue";

export default {
  components: { BaseToolBar, SatisfactionItem, BaseButton },
  data() {
    return {
      lists: {},
      feedback: {},
      loading:false
    };
  },
  props: {},
  computed: {
    ...mapGetters({
      client: "clients/client",
      data: "clients/data"
    }),
    ...mapFields("affaires", ["affaire.affaire_client_id", "affaire.id"]),
    ...mapMultiRowFields("affaires", ["satisfactions"])
  },
  methods: {
    ...mapActions({
      _createSatisfaction: "affaires/createSatisfaction",
      //getAffaires: "affaires/getAffaires",
      getClient: "clients/getClient"
    }),
    createSatisfaction: function() {
      this.feedback = {};
      this.loading = true;
      this._createSatisfaction({
        affaire_id: this.id,
        client_id: this.affaire_client_id
      })
      .catch(error => this.errHandler(error))
      .finally(() => (this.loading = false));
    },
    errHandler: function(error) {
      if (error.response.status === 422) {
        this.feedback = error.response.data.error;
        return;
      }
    }
  },
  mounted() {}
};
</script>
